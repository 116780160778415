import React, {useState} from 'react';
import '../styles/contact.scss';
import { StaticImage } from 'gatsby-plugin-image';


const Contact = ({ heading, subheading }) => {

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formState, setFormState] = useState({name:"", email:"", company:"", website:"", message:""})

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }

    const submit = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
              "form-name": form.getAttribute("name"),
              ...formState
            })
        }).then(() => {
            setFormSubmitted(true)
            form.reset();
            window.dataLayer?.push({
                event: 'form_interaction',
                eventAction: 'submit'
            })
        }).catch(error => console.error(error))
    }

    const handleChange = (e) => {
        setFormState(prevState => {
            let form = prevState;
            form[e.target.getAttribute("name")] = e.target.value;
            return form
        })
    }

    return (
        <section className="ff contact" id="contact">
            <h2 className="ff ff-section-header">{heading}</h2>
            <p className="ff subheader">{subheading}</p>
            <form 
                name="contact"
                method="POST"
                action="/"
                className="ff ff-form" 
                onSubmit={submit}   
                onChange={handleChange}
                data-netlify-honeypot="bot-field"
                netlify="true">
                <input type="hidden" value="contact" name="bot-field" />
                <div className='input small'>
                    <label htmlFor="name">Name</label><input id="name" type="text" name="name" required/>
                </div>
                <div className='input small'>
                    <label htmlFor="company">Company</label><input id="company" type="text" name="company" />
                </div>
                <div className='input small'>
                    <label htmlFor="email">Email</label><input id="email" type="email" name="email" required/>
                </div>
                <div className='input small'>
                    <label htmlFor="website">Website</label><input id="website" type="text" name="website" />
                </div>
                <div className='input large'>
                    <label htmlFor="message">Message</label><textarea id="message" name="message" rows="6"></textarea>
                </div>
                <button className="ff ff-primary" type="submit">{formSubmitted ? 'Speak Soon :)' : 'Send'}</button>
            </form>
            <div className="image">
                <StaticImage src="../assets/team/team-1.jpg" alt="54 Bit Studios team" className="team" quality={100}/>
            </div>


        </section>
    )
}

export default Contact